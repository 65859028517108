import * as Yup from 'yup';

import {
  VoicesEleven,
  VoicesOpenAi
} from 'redux/admin/types/adminState.interface';

import { Background } from './audio.enums';
import { AdminRoles } from './roles.enum';

export const validationSchemaAudios = Yup.object()
  .shape({
    voice: Yup.mixed<VoicesEleven | VoicesOpenAi>()
      .oneOf(
        [...Object.values(VoicesEleven), ...Object.values(VoicesOpenAi)],
        'Invalid voice selection'
      )
      .required('Voice is required'),
    voiceSpeed: Yup.number()
      .min(0.7)
      .max(1.0)
      .required('Voice speed is required'),
    background: Yup.mixed<Background>()
      .oneOf(Object.values(Background), 'Invalid background selection')
      .required('Background is required'),
    backgroundVolume: Yup.number()
      .min(0)
      .max(1)
      .required('Background volume is required'),
    profileName: Yup.string().required('Profile name is required'),
    profileDescription: Yup.string().required(
      'Profile description is required'
    ),
    onboarding: Yup.boolean(),
    library: Yup.boolean()
  })
  .test(
    'onboarding-or-library',
    'Select at least one: Onboarding or Library',
    (values) => values.onboarding || values.library
  );

const AiQualitiesGenerationSchema = Yup.object().shape({
  quality: Yup.string().required('Quality is required'),
  traits: Yup.array()
    .of(Yup.string())
    .required('Traits are required')
    .max(3, 'No more than 3 traits per quality')
});

export const validationSchemaAffirmations = Yup.object().shape({
  developmentArea: Yup.string().required(
    'Choose a Development Area is required'
  ),
  message: Yup.string().required('Message is required'),
  outcome: Yup.string().required('Outcome is required'),
  qualities: Yup.array()
    .of(AiQualitiesGenerationSchema)
    .required('Qualities are required')
    .max(3, 'No more than 3 qualities are allowed')
});

export const validationSchemaCreateAdmin = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format')
    .min(7, 'Email should be at least 7 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password should be at least 4 characters'),
  role: Yup.mixed()
    .oneOf(Object.values(AdminRoles))
    .required('Role is required')
});

export const validationSchemaIntro = Yup.object().shape({
  message: Yup.string().required('Message is required'),
  title: Yup.string().required('Title is required'),
  duration: Yup.number()
    .max(4, 'Intros should not last more than 4 minutes')
    .min(1, 'Intros should last at least 1 minute')
    .required('Duration is required'),
  introText: Yup.string().required('Text is required')
});

export const validationSchemaEditIntro = Yup.object({
  title: Yup.string().required('Title is required'),
  text: Yup.string().required('Text is required')
});
