import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';
import * as Yup from 'yup';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { generatePromotionCode } from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AudioProvider, PromotionCodeData } from 'types/apiResponse.interface';

import { TabOptions } from './PromotionCodeForm';

interface FormValues {
  code: string;
  userNumber: number;
  validity: Date | null;
  credits: number;
  audioProvider: AudioProvider;
}

const initialValues: FormValues = {
  code: '',
  userNumber: 0,
  validity: null,
  credits: 0,
  audioProvider: AudioProvider.ELEVENLABS
};

interface CreatePromotionCodeProps {
  handleTabClick: (tab: TabOptions) => void;
}
export const CreatePromotionCode: React.FC<CreatePromotionCodeProps> = ({
  handleTabClick
}) => {
  const dispatch = useAppDispatch();
  const { ui } = useAppSelector(adminSelector);
  const isLargeScreen = useIsLargeScreen();
  const validationSchema = Yup.object().shape({
    code: Yup.string().required().min(8),
    userNumber: Yup.number().required().min(1),
    validity: Yup.date().nullable().required('Validity date is required'),
    credits: Yup.number().required().min(1).max(10)
  });

  const handleSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    if (values.validity) {
      const data: PromotionCodeData = {
        code: values.code,
        userNumber: values.userNumber,
        validity: new Date(values.validity),
        credits: values.credits,
        audioProvider: values.audioProvider
      };

      dispatch(generatePromotionCode(data));
      resetForm();
      setTimeout(() => handleTabClick(TabOptions.ALL_PROMO), 1000);
    }
  };
  return (
    <Box
      sx={{
        paddingLeft: isLargeScreen ? '1rem' : '0',
        margin: 0,
        width: '100%'
      }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, isValid, handleChange }) => (
          <Form>
            <Grid container alignItems="center" spacing={0} rowGap={2}>
              <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                <Field
                  as={TextField}
                  label="Reference"
                  name="code"
                  variant="outlined"
                  fullWidth
                  sx={{
                    fontFamily: '"Nunito"',
                    fontSize: '1rem',
                    lineHeight: '1.5rem'
                  }}
                />
                {errors.code && (
                  <Typography
                    mt={'0.250rem'}
                    color="error"
                    variant="body1"
                    fontWeight={500}
                    fontSize={'0.75rem'}
                    lineHeight={'0.625rem'}
                    textTransform={'capitalize'}>
                    {errors.code}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                <Field
                  as={TextField}
                  label="Users number"
                  name="userNumber"
                  variant="outlined"
                  fullWidth
                  sx={{
                    fontFamily: '"Nunito"',
                    fontSize: '1rem',
                    lineHeight: '1.5rem'
                  }}
                />
                {errors.userNumber && (
                  <Typography
                    mt={'0.250rem'}
                    color="error"
                    variant="body1"
                    fontWeight={500}
                    fontSize={'0.75rem'}
                    lineHeight={'0.625rem'}
                    textTransform={'capitalize'}>
                    User Number is a Required Field
                  </Typography>
                )}
              </Grid>
              <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '1rem'
                  }}>
                  <Box sx={{ width: '50%', minWidth: '5rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Field name="validity">
                        {({ field, form }: FieldProps) => (
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="Validity"
                            value={field.value}
                            onChange={(value) => {
                              form.setFieldValue(field.name, value);
                            }}
                          />
                        )}
                      </Field>
                    </LocalizationProvider>
                    {errors.validity && (
                      <Typography
                        mt={'0.250rem'}
                        color="error"
                        variant="body1"
                        fontWeight={500}
                        fontSize={'0.75rem'}
                        lineHeight={'0.625rem'}
                        textTransform={'capitalize'}>
                        {errors.validity}
                      </Typography>
                    )}
                  </Box>
                  <Box width={'50%'} minWidth={'5rem'}>
                    <Field
                      as={TextField}
                      label="Credits"
                      name="credits"
                      variant="outlined"
                      fullWidth
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                    />
                    {errors.credits && (
                      <Typography
                        mt={'0.250rem'}
                        color="error"
                        variant="body1"
                        fontWeight={500}
                        fontSize={'0.75rem'}
                        lineHeight={'0.625rem'}
                        textTransform={'capitalize'}>
                        {errors.credits}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  error={touched.audioProvider && !!errors.audioProvider}>
                  <InputLabel>Audio Provider</InputLabel>
                  <Field
                    name="audioProvider"
                    as={Select}
                    label="audioProvider"
                    onChange={handleChange}
                    value={values.audioProvider}>
                    {Object.values(AudioProvider).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>

              <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: 'fit-content',
                      justifyContent: 'flex-start'
                    }}>
                    <Button variant="contained" type="submit">
                      Confirm
                    </Button>
                  </Box>
                  {ui.requestError && (
                    <Typography color="error" alignSelf="flex-end">
                      {ui.requestError}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
