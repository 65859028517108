import { AdminDetailsResponse } from './admin.requests';

export interface IApiResponse<T> {
  success: boolean;
  data: T;
}

export interface CheckMorningAffirmationDTO {
  newMorningAffirmationId: string;
  overwrite?: boolean;
}

export enum AudioProvider {
  ELEVENLABS = 'ELEVENLABS',
  OPENAI = 'OPENAI'
}

export interface PromotionCodeData {
  code: string;
  userNumber: number;
  validity: Date;
  credits: number;
  audioProvider: AudioProvider;
}

export interface PromotionCodeResponse {
  id: string;
  code: string;
  finalDate: string;
}

export interface PromotionCodes {
  code: string;
  invitationsNumber: number;
  validity: Date;
  credits: number;
  createdBy: string;
  audioProvider: AudioProvider;
}

export enum SeedType {
  Affirmation = 'Affirmation',
  Visualization = 'Visualization',
  Meditation = 'Meditation'
}

export interface SeedData {
  file: File;
  seedType: SeedType;
}

export interface SeeedMigrations {
  fileName: string;
  seedType: string;
  adminUser: AdminDetailsResponse;
}
