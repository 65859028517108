import { ReactComponent as Pause } from 'assets/images/PauseCircle.svg';
import { ReactComponent as Play } from 'assets/images/PlaySound.svg';
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import { Box, IconButton, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const AbstractAudioPlayer = ({
  url,
  isPlaying,
  onTogglePlay,
  selfPause = false
}: {
  url: string;
  isPlaying: boolean;
  onTogglePlay: (url: string) => void;
  selfPause?: boolean;
}) => {
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  }, [url]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '5rem'
      }}>
      <IconButton
        sx={{ padding: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          onTogglePlay(url);
        }}>
        {isPlaying ? (
          <Pause style={{ width: '3.25rem', height: '3.25rem' }} />
        ) : (
          <Play style={{ width: '3.25rem', height: '3.25rem' }} />
        )}
      </IconButton>
      <Typography
        fontFamily='"Nunito"'
        fontSize="0.75rem"
        color={isPlaying ? Colors.secondary.main : Colors.neutralGrey}
        mt="4px">
        {isPlaying ? 'Playing...' : 'Play sample'}
      </Typography>
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        width="0"
        height="0"
        controls={false}
        onEnded={() => {
          if (selfPause && isPlaying) {
            onTogglePlay(url);
          }
        }}
      />
    </Box>
  );
};

export default AbstractAudioPlayer;
